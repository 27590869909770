import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import GridView from "../../../containers/grid-view";
import {URLS} from "../../../constants/urls";
import {KEYS} from "../../../constants/keys";
import Content from "../../../components/content";
import YearMonthPicker from "../../../components/year-month-picker";
import {NavLink, useNavigate} from "react-router-dom";
import {useGetAllQuery, usePostQuery} from "../../../hooks/api";
import Form from "../../../containers/form";
import Button from "../../../components/button";
import Field from "../../../containers/form/field";
import {get} from "lodash";
import {ContentLoader} from "../../../components/loader";

const BudgetInsertContainer = () => {
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {data: budget, isLoading: isLoadingBudget} = useGetAllQuery({
        key: [KEYS.budgetStates, month, year], url: URLS.budgetStates, params: {
            params: {
                fiscal_year: parseInt(year),
                month: parseInt(month)
            }
        }, enabled: !!(month && year)
    })
    const {mutate, isLoading} = usePostQuery({listKeyId: [KEYS.budgetStates, month, year]})
    const upsert = ({data}) => {
        mutate({url: URLS.budgetStates, attributes: {...data}}, {
            onSuccess: () => {
                navigate(`/budget`)
            }
        })
    }
    return (
        <div className="grid grid-cols-12">
            {isLoadingBudget && <ContentLoader/>}
            <div className="col-span-12 mb-5">
                <Title>{t("О бюджете")}</Title>
            </div>
            <div className={'col-span-12'}>
                <Content classNames={'!bg-white'}>
                    <div className="grid grid-cols-12 gap-x-8">
                        <div className="col-span-3">
                            <div
                                className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                <NavLink
                                    className={'block border border-[#47748A] rounded-lg text-center p-2.5 mb-3 font-semibold  '}
                                    to={'/budget/by-regions'}>
                                    {t("Информация о бюджете")}
                                </NavLink>
                                <NavLink
                                    className={'block border border-[#E5E5E5] rounded-lg text-center p-2.5 mb-3 font-semibold bg-[#47748A] text-white'}
                                    to={'/budget/insert'}>
                                    {t("Внесение бюджета")}
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-span-9">
                            <div className={'mb-3'}>
                                <YearMonthPicker hasBorder getMonth={setMonth} getYear={setYear}/>
                            </div>
                            {budget && month && year &&
                                <Form formName={`${year}${month}`} classNames={'mb-0'} formRequest={upsert}
                                      footer={<Button loading={isLoading}
                                                      classNames={' mt-6 !bg-primary  text-white   min-w-56 !text-center justify-center'}>Сохранить</Button>}>
                                    <GridView
                                        url={URLS.organizationTerritory}
                                        listKey={KEYS.organizationTerritory}
                                        dataKey={'data'}
                                        metaDataKey={'data.payload.meta'}
                                        params={{
                                            level: 1,
                                            limit: 1000
                                        }}
                                        columns={[
                                            {
                                                title: t('№'),
                                                key: 'id',
                                                render: ({number}) => number,
                                            },
                                            {
                                                title: t('Город'),
                                                key: 'display',
                                                render: ({value, number, row}) => <>
                                                    {value}
                                                    <Field defaultValue={get(row, 'id')} type={'input'}
                                                           property={{type: 'hidden'}}
                                                           name={`budgets[${number - 1}].area.id`}/>
                                                    <Field defaultValue={year} type={'input'}
                                                           property={{type: 'hidden'}}
                                                           name={`budgets[${number - 1}].fiscal_year`}/>
                                                    <Field defaultValue={parseInt(month)} type={'input'}
                                                           property={{type: 'hidden'}}
                                                           name={`budgets[${number - 1}].month`}/>
                                                    {get(budget, `data.payload.data[${number - 1}].id`) && <Field
                                                        defaultValue={get(budget, `data.payload.data[${number - 1}].id`)}
                                                        type={'input'} property={{type: 'hidden'}}
                                                        name={`budgets[${number - 1}].id`}/>}
                                                </>
                                            },
                                            {
                                                title: t('Бюджет на месяц'),
                                                key: 'allocated_amount',
                                                render: ({number}) => <Field
                                                    defaultValue={get(budget, `data.payload.data[${number - 1}].allocated_amount`, 0)}
                                                    type={'number-format-input'}
                                                    name={`budgets[${number - 1}].allocated_amount`}/>
                                            },
                                        ]}
                                    />
                                </Form>}
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default BudgetInsertContainer;
