import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import GridView from "../../../containers/grid-view";
import {URLS} from "../../../constants/urls";
import {KEYS} from "../../../constants/keys";
import Content from "../../../components/content";
import YearMonthPicker from "../../../components/year-month-picker";
import {NumericFormat} from "react-number-format";
import {Download} from "react-feather";
import {ContentLoader} from "../../../components/loader";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import Modal from "../../../components/modal";
import {get, isNil} from "lodash";
import {usePostQuery} from "../../../hooks/api";
import dayjs from "dayjs";
import {FIELDS} from "../../../constants";

const BudgetByOrganizationContainer = () => {
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [row, setRow] = useState(null)
    const [regionId, setRegionId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [filter, setFilter] = useState({})
    const {t} = useTranslation()

    const {mutate, isLoading} = usePostQuery({listKeyId: [KEYS.getBudgetByOrganization,year,month]})
    const upsertBudget = ({data: attrs}) => {
        mutate({
            url: URLS.budgetByOrganization, attributes: {
                ...attrs,
                organization:{id:get(row,'id')},
                month,
                fiscal_year:year
            }
        },{
            onSuccess:()=>{
                setRow(null)
            }
        })
    }
    console.log('cityId',cityId)
    console.log('regionId',regionId)
    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 mb-5">
                <Title>{t("Budget by organization")}</Title>
            </div>
            <div className={'col-span-12'}>
                <Content classNames={'!bg-white'}>
                    <div className="grid grid-cols-12 gap-x-8">
                        <div className="col-span-12">
                            <div className={'mb-3'}>
                                <YearMonthPicker hasBorder getMonth={setMonth} getYear={setYear}/>
                            </div>
                            {year && month && <GridView
                                url={URLS.getBudgetByOrganization}
                                listKey={[KEYS.getBudgetByOrganization,year,month]}
                                dataKey={'data.payload.data'}
                                metaDataKey={'data.payload.meta'}
                                isContentLoader
                                filters={<div className={' py-4 px-2 '}>
                                    <Form resetFields={{
                                        name:'',
                                        inn:'',
                                        state_id: null,
                                        district_id: null,
                                        city_id: null
                                    }}
                                          defaultValues={{...filter}}
                                          classNames={'grid grid-cols-12 gap-x-4 items-start'}
                                          formRequest={({data}) => {
                                              const {
                                                  name,
                                                  inn,
                                                  state_id,
                                                  district_id,
                                                  city_id
                                              } = data;
                                              setFilter({
                                                  name,
                                                  inn,
                                                  state_id: get(state_id, 'id'),
                                                  district_id: get(district_id, 'id'),
                                                  city_id: get(city_id, 'id'),
                                              })
                                          }
                                          }>
                                        <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                               placeholder={t('Наименование')}/>
                                        <Field type={FIELDS.inputMask}
                                               params={{pattern: {value: /^[0-9]{9}$/, message: 'Invalid value'}}}
                                               classNames={'col-span-3'}
                                               name={'pin'}
                                               property={{
                                                   placeholder: t('ИНН'),
                                                   mask: '999999999',
                                                   maskChar: '_',
                                               }}
                                        />


                                        <Field property={{onChange: (val) => setRegionId(get(val, 'id'))}}
                                               classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'state_id'}
                                               placeholder={t('State')}
                                               url={`${URLS.organizationTerritory}?limit=100&level=1`}
                                               keyId={KEYS.organizationTerritory}/>
                                        <Field resetOptions property={{onChange: (val) => setCityId(get(val, 'id'))}}
                                               enabled={!!(regionId)}
                                               classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'city_id'}
                                               placeholder={t('City')}
                                               url={`${URLS.organizationTerritory}?limit=1000&level=2&parent_id=${regionId}`}
                                               keyId={[KEYS.organizationTerritory,regionId]}/>
                                        <Field resetOptions enabled={!!(cityId)} classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'district_id'}
                                               placeholder={t('District')}
                                               url={`${URLS.organizationTerritory}?limit=1000&level=3&parent_id=${cityId}`}
                                               keyId={[KEYS.organizationTerritory,cityId]}/>
                                        <div className={'col-span-6 mt-1.5'}>
                                            <div className="flex items-center">
                                                <button type={'submit'}
                                                        className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                                    {t('Фильтр')}
                                                </button>
                                                <button onClick={() => setFilter({})} type={'reset'}
                                                        className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                                    {t('Очистить фильтр')}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>

                                </div>}
                                params={{
                                    fiscal_year: parseInt(year),
                                    month: parseInt(month),
                                    ...filter
                                }}
                                columns={[
                                    {
                                        title: t('№'),
                                        key: 'id',
                                        render: ({number}) => number,
                                    },
                                    {
                                        title: t('Organization'),
                                        key: 'organization.display'
                                    },
                                    {
                                        title: t('Бюджет на месяц'),
                                        key: 'budget.current_balance',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={' '}/>
                                    },
                                    {
                                        title: t('С прошлого месяца'),
                                        key: 'budget.spent_amount',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={' '}/>
                                    },
                                    {
                                        title: t('Общая сумма'),
                                        key: 'budget.allocated_amount',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={' '}/>
                                    },
                                    {
                                        title: t('Внесение бюджета'),
                                        key: 'id',
                                        render: ({row}) => month >= dayjs().format("MM") && <Download onClick={() => setRow(row)} className={'mx-auto'}
                                                                     color={'#3F6A83'}/>
                                    }
                                ]}
                            />}
                        </div>
                    </div>
                </Content>
            </div>
            <Modal open={!isNil(row)} onClose={() => setRow(null)} classNames={' !pb-0'}
                   title={get(row, 'organization.display', t('Внесение бюджета'))}>
                {(isLoading) && <ContentLoader/>}
                <div
                    className={"pb-6"}>
                    <Form classNames={'grid grid-cols-12 gap-x-6'} formRequest={upsertBudget}
                          footer={<div className={'col-span-12 '}>
                              <div className="flex justify-end">
                                  <button onClick={() => setRow(null)} type={'button'}
                                          className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                      {t('Отмена')}
                                  </button>
                                  <button type={'submit'}
                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                      {t('Сохранить')}
                                  </button>

                              </div>
                          </div>}>

                        <Field
                            classNames={'col-span-12 '}
                            defaultValue={get(row,'budget.current_balance',0)}
                            type={'number-format-input'}
                            name={`allocated_amount`}/>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default BudgetByOrganizationContainer;
