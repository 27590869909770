import React, {useEffect, useState} from 'react';
import Title from "../../../components/title";
import GridView from "../../../containers/grid-view";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {get, isNil} from "lodash"
import downloadIcon from "../../../assets/icons/download.svg"
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Edit2, Eye, Plus} from "react-feather";
import Search from "../../../components/search"
import SelectComponent from "../../../components/select";
import {ContentLoader} from "../../../components/loader";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";

import Modal from "../../../components/modal";
import {useGetAllQuery, usePostQuery, usePutQuery} from "../../../hooks/api";
import {EXCEL_EXPORT_TYPE, FIELDS} from "../../../constants";
import {toast} from "react-toastify";
import Button from "../../../components/button";
import Identifiers from "../../../components/identifiers";
import PatientLocations from "../../../components/locations/PatientLocations";
import PatientContacts from "../../../components/contacts/patient-contacts";
import {Panel, Stack} from "rsuite";


const PractitionersContainer = () => {
    const navigate = useNavigate();
    const [searchParam, setSearchParams] = useSearchParams();
    const [regionId, setRegionId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [filter, setFilter] = useState({})
    const [exportLoading, setExportLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [personData, setPersonData] = useState(null)
    const [rowId, setRowId] = useState(null)
    const {t} = useTranslation();
    const {
        mutate: getPersonInfo, isLoading: isLoadingPersonInfo
    } = usePostQuery({listKeyId: KEYS.persons})
    const {
        mutate: addRequest, isLoading: isLoadingPost
    } = usePostQuery({listKeyId: KEYS.practitioners})
    const {
        mutate: updateRequest, isLoading: isLoadingPatient
    } = usePutQuery({listKeyId: KEYS.practitioners})

    const {
        refetch, isLoading: isLoadingExportExcel
    } = useGetAllQuery({
        listKeyId: KEYS.exportExcel,
        enabled: false,
        url: `${URLS.exportExcel}/${EXCEL_EXPORT_TYPE.Practitioner}`,
        cb: {
            success: (res) => {
                toast.success(get(res, 'message'))
                setExportLoading(false)
            },
            fail: () => {
                setExportLoading(false)
            }
        }
    })
    const {data: dataOne} = useGetAllQuery({
        key: [KEYS.practitioners, rowId],
        url: `${URLS.practitioners}/${rowId}`,
        enabled: !!(rowId)
    })

    const columns = [
        {
            title: t('Ф.И.О'),
            key: 'display',
            render: ({row}) => `${get(row, 'last_name')} ${get(row, 'first_name')} ${get(row, 'middle_name')}`
        },
        {
            title: t('PIN'),
            key: 'pin',
        },
        {
            title: t('Gender'),
            key: 'gender.display',
        },
        {
            title: t('Действия'),
            key: 'id',
            render: ({value}) => {
                return <div className={'flex justify-center'}><Eye
                    onClick={() => navigate(`/hrm/practitioners/view/${value}?tab=info`)}
                    className={'mr-3.5 '} size={22}/><Edit2 color={'#2F68FC'} className={' inline'}
                                                            size={22} onClick={() => setRowId(value)}/>
                </div>
            }
        }
    ]
    const sortOptions = [
        {label: t('Дата обновления (по возрастанию)'), value: "updated_at"},
        {label: t('Дата обновления (по убыванию)'), value: "-updated_at"},
        {label: t('Имя (по возрастанию)'), value: "first_name"},
        {label: t('Имя (по убыванию)'), value: "-first_name"},
        {label: t('Фамилия (по возрастанию)'), value: "last_name"},
        {label: t('Фамилия (по убыванию)'), value: "-last_name"}
    ];

    const closeModal = () => {
        setRowId(null);
        setOpen(false)
        setPersonData({})
        setSearchParams(``)
    }

    useEffect(() => {
        console.log('row', rowId)
        if (get(dataOne, 'data') && rowId) {
            console.log('dataOne', dataOne)
            setPersonData(get(dataOne, 'data.payload.practitioner'))
        }
    }, [dataOne, rowId])

    const onSubmit = ({data}, tab) => {
        if (tab === 'person') {
            const {contacts, ...rest} = data;
            if (personData?.id) {
                setSearchParams(`tab=info`)
            } else {
                getPersonInfo({
                    url: URLS.persons,
                    attributes: {
                        ...rest
                    }
                }, {
                    onSuccess: (response) => {
                        setPersonData(prev => ({...prev, ...get(response, 'data.payload.person')}))
                    }
                })
            }
        } else {
            setPersonData(prev => ({...prev, ...data}))
            setSearchParams(`tab=${tab}`)
        }
    }
    const addPractitioner = ({data: formData}) => {
        const {id, ...rest} = formData;
        addRequest({
            url: URLS.practitioners,
            attributes: {
                ...rest,
                person_id: id
            }
        }, {
            onSuccess: () => {
                closeModal();
            }
        })
    }
    const updatePractitioner = ({data: formData}) => {
        const {...rest} = formData;
        updateRequest({
            url: `${URLS.practitioners}/${rowId}`,
            attributes: {
                ...rest
            }
        }, {
            onSuccess: () => {
                closeModal();
            }
        })
    }
    useEffect(() => {
        if (searchParam.get('practitionerId')) {
            setRowId(searchParam.get('practitionerId'))
        }
    }, [searchParam.get('practitionerId')]);
    return (
        <div>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-6">
                    <Title>{t("Сотрудники")}</Title>
                </div>
                <div className="col-span-6 flex items-center justify-end">
                    <Button onClick={() => setOpen(true)} classNames={'!pr-5 bg-primary  text-white mr-4'}
                            icon={<Plus className={'mr-1.5'}/>}>
                        {t('Добавить сотрудников')}
                    </Button>
                    <Button loading={exportLoading} onClick={() => {
                        setExportLoading(true)
                        refetch()
                    }} classNames={'!pr-5 !text-sm'}
                            icon={<img className={'mr-2.5'} src={downloadIcon} alt={'export'}/>}>
                        {t("Export")}
                    </Button>

                </div>
                <div className="col-span-12 mt-6">
                    <GridView
                        getRowId={setRowId}
                        params={{
                            ...filter
                        }}
                        hasActionColumn={false}
                        listKey={[KEYS.practitioners, filter]} url={URLS.practitioners}
                        dataKey={'data.payload.data'}
                        columns={columns}
                        isContentLoader
                        filters={<div className={' py-4 px-2 '}>
                            <Form resetFields={{
                                name: '',
                                pin: '',
                                sort: '-updated_at',
                                state_id: null,
                                district_id: null,
                                city_id: null
                            }}
                                  defaultValues={{...filter}}
                                  classNames={'grid grid-cols-12 gap-x-4 items-start'}
                                  formRequest={({data}) => {
                                      const {
                                          name,
                                          pin,
                                          sort,
                                          state_id,
                                          district_id,
                                          city_id
                                      } = data;
                                      setFilter({
                                          name,
                                          pin,
                                          sort: get(sort, 'value'),
                                          state_id: get(state_id, 'id'),
                                          district_id: get(district_id, 'id'),
                                          city_id: get(city_id, 'id'),
                                      })
                                  }
                                  }>
                                <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                       placeholder={t('Ф.И.О')}/>
                                <Field type={FIELDS.inputMask}
                                       params={{pattern: {value: /^[0-9]{14}$/, message: 'Invalid value'}}}
                                       classNames={'col-span-3'}
                                       name={'pin'}
                                       property={{
                                           placeholder: t('ПИНФЛ'),
                                           mask: '99999999999999',
                                           maskChar: '_',
                                       }}
                                />


                                <Field property={{onChange: (val) => setRegionId(get(val, 'id'))}}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'state_id'}
                                       placeholder={t('State')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=1`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field property={{onChange: (val) => setCityId(get(val, 'id'))}}
                                       enabled={!!(regionId)}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'city_id'}
                                       placeholder={t('City')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=2&parent_id=${regionId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field enabled={!!(cityId)} classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'district_id'}
                                       placeholder={t('District')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=3&parent_id=${cityId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field type={FIELDS.select}
                                       classNames={'col-span-3'}
                                       name={'sort'}
                                       placeholder={t('Sort')}
                                       options={sortOptions}
                                       property={{optionValue: 'value', optionLabel: 'label'}}
                                />
                                <div className={'col-span-6 mt-1.5'}>
                                    <div className="flex items-center">
                                        <button type={'submit'}
                                                className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                            {t('Фильтр')}
                                        </button>
                                        <button onClick={() => setFilter({})} type={'reset'}
                                                className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                            {t('Очистить фильтр')}
                                        </button>
                                    </div>
                                </div>
                            </Form>

                        </div>}
                    />

                </div>
            </div>
            <Modal open={open || !isNil(rowId)} onClose={closeModal} classNames={'!w-[1200px]'}
                   title={t(rowId ? 'Изменить сотрудника' : 'Добавить сотрудника')}
            >
                {(isLoadingPost || isLoadingPersonInfo) && <ContentLoader/>}
                {
                    isNil(rowId) && <Form defaultValues={{...personData}} classNames={'grid grid-cols-12 gap-x-6'}
                                          formRequest={(data) => onSubmit(data, 'person')}
                                          footer=""
                    >
                        <Field type={'input-mask'}
                               params={{
                                   required: true,
                                   pattern: {value: /^[A-Z a-z]{2}[0-9]{7}$/, message: 'Invalid value'}
                               }}
                               classNames={'col-span-6'}
                               name={'passport'}
                               defaultValue={get(personData, 'passport')}
                               property={{
                                   placeholder: t('Серия и номер паспорта'),
                                   mask: 'aa9999999',
                                   maskChar: '_',
                                   className: 'uppercase'
                               }}
                               label={<div className={'flex'}><span>{t('Серия и номер паспорта')}</span></div>}
                        />
                        <Field type={'input-mask'}
                               params={{required: true, pattern: {value: /^[0-9]{14}$/, message: 'Invalid value'}}}
                               classNames={'col-span-4'}
                               name={'pin'}
                               defaultValue={get(personData, 'pin')}
                               property={{
                                   placeholder: t('ПИНФЛ'),
                                   mask: '99999999999999',
                                   maskChar: '_',
                               }}
                               label={<div className={'flex'}><span>{t('ПИНФЛ')}</span></div>}
                        />
                        <div className="col-span-2">
                            <button type={'submit'}
                                    className={'py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-7 w-full'}>
                                {t('Поиск')}
                            </button>
                        </div>
                    </Form>
                }
                {personData?.id && <Form defaultValues={{identifiers: [{}], telecoms: [{}], ...personData}}
                                         formRequest={(data) => rowId ? updatePractitioner(data) : addPractitioner(data)}
                                         name={'patientForm'} classNames={'grid grid-cols-12 gap-x-6 mt-3'}
                                         footer={<div className={'col-span-12 '}>
                                             <div className="flex justify-end">
                                                 <button type={'submit'}
                                                         className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                                     {t('Сохранить')}
                                                 </button>
                                             </div>
                                         </div>}>
                    <Panel style={{overflow: 'unset'}} bordered className={'col-span-12'} shaded>
                        <Title sm className={'mb-3'}>{t("Удостоверяющий личность документ")}</Title>
                        <Identifiers/>
                    </Panel>
                    <Panel style={{overflow: 'unset'}} bordered className={'col-span-12 mt-4'} shaded>
                        <Title sm className={'mb-3'}>{t("Основные данные")}</Title>
                        <div className={'grid grid-cols-12 gap-x-6 '}>
                            <Field type={FIELDS.input} params={{
                                required: true,
                            }}
                                   defaultValue={get(personData, 'first_name')}
                                   classNames={'col-span-4'}
                                   name={'first_name'}
                                   placeholder={t('Имя')}
                                   property={{type: 'text'}}
                                   label={t('Имя')}
                            />
                            <Field type={FIELDS.input} params={{
                                required: true,
                            }}
                                   defaultValue={get(personData, 'last_name')}
                                   classNames={'col-span-4'}
                                   name={'last_name'}
                                   placeholder={t('Фамилия')}
                                   property={{type: 'text'}}
                                   label={t('Фамилия')}
                            />
                            <Field type={FIELDS.input} params={{
                                required: true,
                            }}
                                   defaultValue={get(personData, 'middle_name')}
                                   classNames={'col-span-4'}
                                   name={'middle_name'}
                                   placeholder={t('Отчество')}
                                   property={{type: 'text'}}
                                   label={t('Отчество')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(personData, 'nationality')}
                                   isDisabledSearch
                                   url={URLS.nationality}
                                   keyId={KEYS.nationality}
                                   classNames={'col-span-4'}
                                   name={'nationality'}
                                   limit={1000}
                                   label={t('Национальность')}
                                   params={{required: true}}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(personData, 'citizenship')}
                                   isDisabledSearch
                                   url={URLS.citizenship}
                                   keyId={KEYS.citizenship}
                                   classNames={'col-span-4'}
                                   name={'citizenship'}
                                   limit={1000}
                                   label={t('Гражданство')}
                                   params={{required: true}}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(personData, 'birth_country')}
                                   isDisabledSearch
                                   url={URLS.citizenship}
                                   keyId={KEYS.citizenship}
                                   classNames={'col-span-4'}
                                   name={'birth_country'}
                                   limit={1000}
                                   label={t('Страна рождения')}
                                   params={{required: true}}
                            />
                            <Field type={FIELDS.datepicker}
                                   dateFormat={"yyyy-MM-dd"}
                                   params={{
                                       required: true,
                                   }}
                                   defaultValue={get(personData, 'birth_date')}
                                   classNames={'col-span-2'}
                                   name={'birth_date'}
                                   placeholder={t('Дата рождения')}
                                   label={t('Дата рождения')}
                            />
                            <Field type={FIELDS.input}
                                   defaultValue={get(personData, 'birth_place')}
                                   classNames={'col-span-10'}
                                   name={'birth_place'}
                                   placeholder={t('Место рождения')}
                                   property={{type: 'text'}}
                                   label={t('Место рождения')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(personData, 'gender')}
                                   isDisabledSearch
                                   url={URLS.gender}
                                   keyId={KEYS.gender}
                                   classNames={'col-span-4'}
                                   name={'gender'}
                                   limit={1000}
                                   label={t('Пол')}
                                   params={{required: true}}
                            />
                        </div>
                    </Panel>


                    <Panel style={{overflow: 'unset'}} bordered className={'col-span-12 mt-4'} shaded>
                        <Title sm className={'mb-3'}>{t("Адрес")}</Title>
                        <div className={'grid grid-cols-12 gap-x-6 '}>
                            <PatientLocations data={personData} name={'addresses'}/>
                        </div>
                    </Panel>

                    <Panel style={{overflow: 'unset'}} bordered className={'col-span-12 mt-4'} shaded>
                        <Title sm className={'mb-3'}>{t("Контакты")}</Title>
                        <div className={'grid grid-cols-12 gap-x-6 '}>
                            <PatientContacts hasSubtitle={false} data={{...personData}}/>
                        </div>
                    </Panel>
                </Form>}
            </Modal>
        </div>
    );
};

export default PractitionersContainer;
