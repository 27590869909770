import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { AllowedAccess } from "react-permission-role";
import config from "../../config";

const Menu = ({children}) => {
    const {t} = useTranslation()
    return (
         <ul className={'flex items-center relative  z-50 flex-wrap'}>
             {children ? children : <>
            <AllowedAccess  permissions={[config.PERMISSIONS.dashboard_view]}>

                <li className={'menu__item group'}>
                    <NavLink className={'menu__item_link'} to={'/'}>{t("Статистика")}</NavLink>
                    <ul className="absolute z-50 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3 w-[225px]">
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/statistics/cartography'}>{t("Картография")}</NavLink>
                        </li>
                    </ul>
                </li>
            </AllowedAccess>
            <AllowedAccess  permissions={[config.PERMISSIONS.organization_view]}>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/hrm/organizations'}>{t("Организации")}</NavLink>
            </li>
            </AllowedAccess>
            <AllowedAccess  permissions={[config.PERMISSIONS.practitioner_view]}>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/hrm/practitioners'}>{t("Сотрудники")}</NavLink>
            </li>
            </AllowedAccess>
            <AllowedAccess   roles={[config.ROLES.ROLE_ADMIN,config.ROLES.ROLE_PHARMACIST,config.ROLES.HOSPITAL_CHAIRMAN,config.ROLES.ROLE_SUPER_ADMIN]} >
                <li className={'menu__item group'}>
                    <NavLink className={'menu__item_link'} to={'/pharmacy-search'}>{t("Pharmacy")}</NavLink>
                </li>
            </AllowedAccess>
            <AllowedAccess  permissions={[config.PERMISSIONS.patient_view]}>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/patient'}>{t("Пациент")}</NavLink>
            </li>
            </AllowedAccess>

            <AllowedAccess   roles={[config.ROLES.ROLE_ADMIN,config.ROLES.HOSPITAL_CHAIRMAN,config.ROLES.ROLE_SUPER_ADMIN]}>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/medication-request'}>{t("Рецепт")}</NavLink>
                <ul className="absolute z-50 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3 w-[225px]">
                    <AllowedAccess   roles={[config.ROLES.ROLE_ADMIN,config.ROLES.HOSPITAL_CHAIRMAN,config.ROLES.ROLE_SUPER_ADMIN]}>
                    <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/medication-request'}>{t("Рецепт")}</NavLink>
                    </li>
                    </AllowedAccess>
                    <AllowedAccess   roles={[config.ROLES.HOSPITAL_CHAIRMAN,config.ROLES.ROLE_SUPER_ADMIN]}>
                    <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/chairman/medication-request'}>{t("Председатель")}</NavLink>
                    </li>
                    </AllowedAccess>
                </ul>
            </li>
            </AllowedAccess>
            <AllowedAccess   roles={[config.ROLES.ROLE_ECONOMIST,config.ROLES.ROLE_ADMIN,config.ROLES.ROLE_SUPER_ADMIN]}>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/budget'}>{t("Budget")}</NavLink>
                <ul className="absolute z-50 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3 w-[225px]">
                    <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/budget/report'}>{t("Budget reports")}</NavLink>
                    </li>
                    <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/budget/by-regions'}>{t("Информация о бюджете")}</NavLink>
                    </li>
                    <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/budget/insert'}>{t("Внесение бюджета")}</NavLink>
                    </li>
                    <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/budget/by-organizations'}>{t("Budget by organizations")}</NavLink>
                    </li>
                </ul>
            </li>
            </AllowedAccess>
                 <AllowedAccess  permissions={[config.PERMISSIONS.patient_view]}>
                     <li className={'menu__item group'}>
                         <NavLink className={'menu__item_link'} to={'/encounters/imp'}>{t("Форма 066")}</NavLink>
                     </li>
                 </AllowedAccess>
            <AllowedAccess roles={[config.ROLES.ROLE_SUPER_ADMIN]}>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/settings'}>{t("Администрирование")}</NavLink>
                <ul className="absolute z-50 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3 w-[225px]">
                    <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/settings/translations'}>{t("Переводы")}</NavLink>
                    </li>
                </ul>
            </li>
            </AllowedAccess>
             </>}
        </ul>
    );
};

export default Menu;
