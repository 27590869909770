import React, {useState, useEffect, useRef, memo, useCallback} from 'react';
import {components} from 'react-select';
import RAsyncSelect from 'react-select/async';
import clsx from "clsx";
import arrowIcon from "../../../assets/icons/select-arrow.svg";
import {Controller, useFormContext} from "react-hook-form";
import {find, get, hasIn, isEqual, isFunction, last, isArray, isEmpty, includes, isString, isObject} from "lodash";
import {useTranslation} from "react-i18next";
import config from "../../../config";
import {useSettingsStore, useStore} from "../../../store";
import Label from "../../../components/label";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <img src={arrowIcon} alt={'arrow'}/>
            </components.DropdownIndicator>
        )
    );
};
const customStyles = (hasError = false) => ({
    control: (base) => ({
        ...base,
        background: "#fff",
        borderColor: hasError ? "red" : "rgba(0, 0, 0, 0.1)",
        borderRadius: '8px',
        outline: "none",
        display: "flex",
        overflow: 'hidden',
        padding: '4px 12px',
        width: '100%',
        minWidth: '200px',
        minHeight: '48px',
        fontSize: '16px',
        fontWeight: '400',
        "&:hover": {
            borderColor: '#006D85',
            outline: "none",
        },
        "&:focus": {
            borderColor: '#006D85',
            outline: "none",
        }
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    menu: (base) => ({...base, position: 'absolute', zIndex: 9})
});
const fetchOptions = async (url, headers = {}) => {
    const response = await fetch(url, {
        headers
    });
    const data = await response.json();
    return data;
};

const AsyncSelect = ({
                         property,
                         isMulti = false,
                         name,
                         placeholder = 'Не выбран',
                         params,
                         label = '',
                         classNames = '',
                         defaultValue = undefined,
                         url = '',
                         isDisabled = false,
                         enabled = true,
                         resetOptions = false,
                     }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [options, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const user = useStore(state => get(state, "user", null))
    const selectRef = useRef();
    const {control, watch, getValues, reset, formState: {errors}} = useFormContext()
    const token = useSettingsStore(state => get(state, 'token', null))
    const lang = useSettingsStore(state => get(state, 'lang', null))
    const {t} = useTranslation()
    const isError = hasIn(errors, name);


    useEffect(() => {
        if (isFunction(get(property, 'onChange'))) {
            get(property, 'onChange')(getValues(name), name);
        }

    }, [watch(name)]);


    useEffect(() => {
        if (get(property, 'defaultSearchAttrName') && defaultValue) {
            if (getDefaultValue(options, defaultValue)) {
                reset(prev => {
                    return {...prev, [name]: getDefaultValue(options, defaultValue)}
                })
            }
        }
    }, [get(property, 'defaultSearchAttrName'), defaultValue]);

    const loadMoreOptions = useCallback(async (_inputValue = '') => {
        if (isLoading || (!hasMore && !_inputValue)) return;
        setIsLoading(true);
        try {
            let fetchUrl = includes(url, '?') ? `${config.API_ROOT}${url}&page=${page}` : `${config.API_ROOT}${url}?page=${page}`
            if (_inputValue && isString(_inputValue) && !(_inputValue instanceof WheelEvent)) {
                fetchUrl = `${config.API_ROOT}${url}?page=1&q=${_inputValue}`
            }
            const data = await fetchOptions(fetchUrl, {
                Authorization: `Bearer ${token}`,
                'PractitionerRole-Id': last(get(user, 'practitioner_role', [])),
                'Accept-Language': lang
            });
            let newOptions = isArray(data) ? data : get(data, 'payload.data', [])
            if (!isEmpty(get(property, 'disabledOptions', []))) {
                if (isArray(newOptions)) {
                    newOptions = newOptions.map(_option => ({
                        ..._option,
                        isDisabled: includes(get(property, 'disabledOptions', []), get(_option, 'id'))
                    }));
                }
            }
            if (isArray(newOptions)) {
                if (resetOptions) {
                    setOptions(newOptions);
                } else {
                    setOptions((prevOptions) => [...prevOptions, ...newOptions]);
                }
            }

            if (_inputValue) {
                setOptions(newOptions);
            }
            setPage((prevPage) => prevPage + 1);
            setHasMore(get(data, 'payload.meta.has_next_page', false));
            return newOptions;
        } catch (error) {
            console.error("Failed to load options:", error);
        } finally {
            setIsLoading(false);
        }

    }, [page, isLoading, hasMore, url, enabled]);


    const getDefaultValue = (_options = [], _defaultValue = null) => {
        return find(_options, (_option) => isEqual(get(_option, get(property, 'defaultSearchAttrName')), _defaultValue)) || undefined
    }


    useEffect(() => {
        if (enabled) {
            loadMoreOptions(inputValue);
        }
    }, [enabled, url, inputValue]);

    return (
        <div className={clsx(`form-group ${classNames}`)}>
            <Label isError={isError} isRequired={get(params, 'required', false)}>{label ?? name}</Label>
            <Controller
                as={RAsyncSelect}
                control={control}
                name={name}
                rules={params}
                defaultValue={get(property, 'defaultSearchAttrName') ? getDefaultValue(options, defaultValue) : defaultValue}
                render={({field}) => <RAsyncSelect
                    {...field}
                    ref={selectRef}
                    name={name}
                    isClearable
                    getOptionLabel={(option) => get(option, get(property, 'optionLabel', 'display'))}
                    getOptionValue={(option) => get(option, get(property, 'optionValue', 'id'))}
                    clearIndicator={true}
                    styles={customStyles(hasIn(errors, name))}
                    components={{DropdownIndicator}}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    options={options}
                    defaultOptions={options}
                    loadOptions={loadMoreOptions}
                    isLoading={isLoading}
                    cacheOptions
                    isDisabled={isDisabled}
                    onMenuScrollToBottom={loadMoreOptions}
                    onInputChange={(val) => {
                        setInputValue(val);
                        setIsLoading(false);
                        setHasMore(true);
                    }}
                />}
            />
            {get(errors, `${name}.type`) === 'required' &&
                <span className={'form-error'}>{t('Заполните обязательное поле')}</span>}
            {get(errors, `${name}.type`) === 'validation' &&
                <span className={'form-error'}>{get(errors, `${name}.message`)}</span>}
        </div>
    );
};

export default memo(AsyncSelect);
