import React, {useEffect, useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {ChevronLeft, Edit2, Plus} from "react-feather";
import {useGetAllQuery, usePostQuery, usePutQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {ContentLoader, OverlayLoader} from "../../../components/loader";
import {get, includes, isNil, isEqual, head, entries, last, isEmpty} from "lodash";
import Content from "../../../components/content";
import {KEYS} from "../../../constants/keys";
import {Tab, Tabs} from "../../../components/tab";
import Field from "../../../containers/form/field";
import {FIELDS, WEEK_DAYS} from "../../../constants";
import Form from "../../../containers/form";
import {toast} from "react-toastify";
import {Button, ButtonToolbar, Message} from "rsuite";
import Modal from "../../../components/modal";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import clsx from "clsx";
import TimePicker from 'react-time-picker';
import Label from "../../../components/label";

const WORKING_HOURS = {
    1: {start_time: '09:00', end_time: '18:00'},
    2: {start_time: '09:00', end_time: '18:00'},
    3: {start_time: '09:00', end_time: '18:00'},
    4: {start_time: '09:00', end_time: '18:00'},
    5: {start_time: '09:00', end_time: '18:00'}
}
const PractitionerContainer = ({id = null}) => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoadingCheck, setIsLoadingCheck] = useState(false);
    const [showWorkplaceModal, setShowWorkplaceModal] = useState(false);
    const [row, setRow] = useState(null);
    const [availableTimes, setAvailableTimes] = useState(WORKING_HOURS);
    const {data, isLoading, refetch} = useGetAllQuery({
        key: [KEYS.practitioners, id],
        url: `${URLS.practitioners}/${id}`
    })
    const {data: checkData, isLoading: isLoadingCheckUser, refetch: checkUser} = useGetAllQuery({
        key: ['user', id],
        url: `${URLS.practitioners}/${id}/user`,
        enabled: isNil(get(data, 'data.payload.practitioner.user')) && isEqual(searchParams.get('tab'), 'role')
    })
    const {data: roles, isLoading: isLoadingRoleList} = useGetAllQuery({key: KEYS.roles, url: URLS.roles})
    const {mutate, isLoading: isLoadingRole} = usePostQuery({listKeyId: [KEYS.practitioners, id]})
    const {
        mutate: addWorkplaceRequest,
        isLoading: isLoadingWorkplace
    } = usePostQuery({listKeyId: [KEYS.practitioners, id]})
    const {
        mutate: editWorkplaceRequest,
        isLoading: isLoadingWorkplaceEdit
    } = usePutQuery({listKeyId: [KEYS.practitioners, id]})

    const setRole = ({data: attrs}) => {
        const {roles: roleList = []} = attrs;
        mutate({
            url: `/practitioners/${id}/user`,
            attributes: {
                roles: get(roles, 'data.payload.data', [])?.filter((_role, index) => _role && roleList[index]).map(({id}) => id) || []
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }

    const addOrEditWorkplace = ({data: attrs}) => {
        if(!isNil(row)){
            editWorkplaceRequest({
                url: `/practitioner-roles/${get(row,'id')}`,
                attributes: {
                    ...row,
                    ...attrs,
                    practitioner: {id: id},
                    available_times: entries(availableTimes).map(item => ({
                        day_of_week: {id: head(item)},
                        start_time: get(last(item), 'start_time'),
                        end_time: get(last(item), 'end_time')
                    }))
                }
            }, {
                onSuccess: () => {
                    refetch()
                    setShowWorkplaceModal(false)
                    setRow(null)
                    setAvailableTimes(WORKING_HOURS)
                }
            })
        }else {
            addWorkplaceRequest({
                url: `/practitioner-roles`,
                attributes: {
                    ...attrs,
                    practitioner: {id: id},
                    available_times: entries(availableTimes).map(item => ({
                        day_of_week: {id: head(item)},
                        start_time: get(last(item), 'start_time'),
                        end_time: get(last(item), 'end_time')
                    }))
                }
            }, {
                onSuccess: () => {
                    refetch()
                    setShowWorkplaceModal(false)
                    setRow(null)
                    setAvailableTimes(WORKING_HOURS)
                }
            })
        }
    }
    useEffect(() => {
        if (checkData && isNil(get(data, 'data.payload.practitioner.user')) && isEqual(searchParams.get('tab'), 'role')) {
            setIsLoadingCheck(false)
            if (get(checkData, 'data.success')) {
                toast.success(get(checkData, 'data.message'))
            } else {
                toast.error(get(checkData, 'data.message'))
            }
            refetch()
        }
    }, [checkData]);
    useEffect(() => {
        if(!isEmpty(get(row, 'available_times', []))) {
            setLastAvailableTimes(get(row, 'available_times', []))
        }
    }, [row]);

    const setLastAvailableTimes = (list=[])=>{
        let lastAvailableTimes = {};
        list.forEach(time=>{
            lastAvailableTimes[get(time,'day_of_week.id')]={
                start_time:get(time,'start_time'),
                end_time:get(time,'end_time')
            }
        })
        setAvailableTimes(lastAvailableTimes)
        return;
    }

    if (isLoading || isLoadingRoleList) {
        return <OverlayLoader/>
    }

    console.log('row', row)
    return (<div>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={'/hrm/practitioners'}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                </div>
                <div className="col-span-12 mb-3">
                    <Title>{get(data, 'data.payload.practitioner.display')}</Title>
                </div>
                <div className="col-span-12">
                    <Tabs hasActiveBg>
                        <Tab tab={'info'} label={t('Personal info')}>
                            <Content sm>
                                <div className="grid grid-cols-12 gap-x-16">
                                    <div className="col-span-12 text-right">
                                        <button
                                            onClick={() => navigate(`/hrm/practitioners?practitionerId=${get(data, 'data.payload.practitioner.id')}`)}
                                            className={'inline-flex items-center py-2 px-4 text-sm font-bold text-primary border border-primary rounded-lg'}>
                                            <Edit2 size={18} className={'mr-2'}/>
                                            {t("Редактировать данные")}
                                        </button>
                                    </div>
                                    <div className="col-span-12">
                                        <div className={'flex pb-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("ПИНФЛ")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.pin')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Пол")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.gender.display')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Birthdate")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.birth_date')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Телефон")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.telecoms[0].value')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Nationality")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.citizenship.display')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Address")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.addresses[0].line')}</strong>
                                        </div>
                                    </div>
                                </div>
                            </Content>
                        </Tab>
                        <Tab tab={'role'} label={t('Roles')}>
                            <Content sm>
                                {isLoadingRole && <OverlayLoader/>}
                                {isLoadingCheckUser ?
                                    <ContentLoader/> : (isNil(get(data, 'data.payload.practitioner.user')) ?
                                        <Message showIcon type="warning" header={t("User not registered yet")}>
                                            <ButtonToolbar>
                                                <Button loading={isLoadingCheck} onClick={() => {
                                                    setIsLoadingCheck(true);
                                                    checkUser();
                                                }} size="sm">{t("Check user")}</Button>
                                            </ButtonToolbar>
                                        </Message> :
                                        <div className="grid grid-cols-12 gap-x-16">
                                            <div className="col-span-12">
                                                <Form classNames={'grid grid-cols-12 gap-x-6'}
                                                      formRequest={(data) => setRole(data)}
                                                      footer={<div className="col-span-12 text-right">
                                                          <button type={'submit'}
                                                                  className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center mt-7'}>
                                                              {t('Сохранить')}
                                                          </button>
                                                      </div>}>
                                                    {
                                                        get(roles, 'data.payload.data', []).map((role, index) => <Field
                                                            type={FIELDS.switch}
                                                            defaultValue={includes(get(data, 'data.payload.practitioner.user.roles', []), get(role, 'code'))}
                                                            classNames={'col-span-4 mb-5'}
                                                            name={`roles[${index}]`}
                                                            label={get(role, 'display')}
                                                        />)
                                                    }
                                                </Form>
                                            </div>
                                        </div>)}
                            </Content>
                        </Tab>
                        <Tab tab={'workplace'} label={t('Workplace')}>
                            <Content sm>
                                <div className="grid grid-cols-12 gap-x-12">
                                    <div className="col-span-12 text-right">
                                        <button
                                            onClick={() => setShowWorkplaceModal(true)}
                                            className={'inline-flex items-center py-2 px-4 text-sm font-bold text-primary border border-primary rounded-lg'}>
                                            <Plus size={18} className={'mr-2'}/>
                                            {t("Add workplace")}
                                        </button>
                                    </div>
                                    {get(data, 'data.payload.practitioner.roles', []).map(item => <div
                                        key={get(item, 'id')} className="col-span-12 mb-6 rounded shadow-card p-6">
                                        <div className="flex mb-3 text-right justify-end">
                                            <Edit2 className={'cursor-pointer'} onClick={() => setRow(item)}/>
                                        </div>
                                        <div
                                            className={clsx("grid grid-cols-12  gap-x-12", {"items-center": !get(item, 'available_times', [])?.length})}>
                                            <div className="col-span-7">
                                                <div className={'flex py-5 border-b items-center'}>
                                                    <span className={'w-1/3'}>{t("Organization")}:</span>
                                                    <strong
                                                        className={'w-2/3'}>{get(item, 'organization.display')}</strong>
                                                </div>
                                                <div className={'flex py-5 border-b items-center'}>
                                                    <span className={'w-1/3'}>{t("Department")}:</span>
                                                    <strong
                                                        className={'w-2/3'}>{get(item, 'position.department.display', '-')}</strong>
                                                </div>
                                                <div className={'flex py-5 border-b items-center'}>
                                                    <span className={'w-1/3'}>{t("Position by classificator")}:</span>
                                                    <strong
                                                        className={'w-2/3'}>{get(item, 'position.role.display', '-')}</strong>
                                                </div>
                                                <div className={'flex py-5 border-b items-center'}>
                                                    <span className={'w-1/3'}>{t("Position name")}:</span>
                                                    <strong
                                                        className={'w-2/3'}>{get(item, 'position.name', '-')}</strong>
                                                </div>
                                                <div className={'flex py-5 border-b items-center'}>
                                                    <span className={'w-1/3'}>{t("Start date")}:</span>
                                                    <strong
                                                        className={'w-2/3'}>{get(item, 'period_start', '-')}</strong>
                                                </div>
                                                <div className={'flex py-5 border-b items-center'}>
                                                    <span className={'w-1/3'}>{t("End date")}:</span>
                                                    <strong
                                                        className={'w-2/3'}>{get(item, 'period_end', '-')}</strong>
                                                </div>
                                            </div>
                                            <div className="col-span-5">
                                                {isEmpty(get(item, 'available_times', [])) ?
                                                    <h3 className={'text-center font-semibold text-lg'}>{t("Вы не указали рабочее время")}</h3> : <>
                                                        {
                                                            get(item, 'available_times', []).map((day, i) => <div
                                                                className={'flex py-5 border-b items-center'}>
                                                                <span
                                                                    className={'w-1/3'}>{t(WEEK_DAYS[i]?.label)}:</span>
                                                                <strong
                                                                    className={'w-2/3'}>{get(day, 'start_time', '-')} {t("до")} {get(day, 'end_time', '-')}</strong>
                                                            </div>)
                                                        }
                                                    </>}
                                            </div>
                                        </div>

                                    </div>)}
                                </div>

                            </Content>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <Modal open={showWorkplaceModal || !isNil(row)} onClose={() => {
                setShowWorkplaceModal(false);
                setRow(null);
            }}
                   classNames={'!w-[600px] !pb-0'}
                   title={!isNil(row) ? t('Edit workplace') : t('Add workplace')}>
                {(isLoadingWorkplace || isLoadingWorkplaceEdit) && <ContentLoader/>}
                <div
                    className={"pb-6"}>
                    <Form defaultValues={{...row}} classNames={'grid grid-cols-12 gap-x-6'} formRequest={addOrEditWorkplace}
                          footer={<div className={'col-span-12 '}>
                              <div className="flex justify-end">
                                  <button onClick={() => {
                                      setShowWorkplaceModal(false);
                                      setRow(null);
                                  }} type={'button'}
                                          className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                      {t('Отмена')}
                                  </button>
                                  <button type={'submit'}
                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                      {t('Сохранить')}
                                  </button>
                              </div>
                          </div>}>
                        <Field
                            label={t('Department')}
                            classNames={'col-span-12'}
                            type={FIELDS.asyncSelect}
                            url={URLS.organizationDepartments}
                            keyId={KEYS.organizationDepartments}
                            name={`organization`}/>
                        <Field
                            label={t('Position')}
                            classNames={'col-span-12'}
                            type={FIELDS.asyncSelect}
                            url={URLS.organizationPositions}
                            keyId={KEYS.organizationPositions}
                            params={{required: true}}
                            defaultValue={get(row,'position')}
                            name={`position`}/>
                        <Field type={'input'}
                               placeholder={'Введите количество'}
                               classNames={'col-span-12'}
                               name={'position.rate'}
                               label={t('Общая ставка')}
                               params={{required: true}}
                               defaultValue={get(row,'position.rate')}
                        />
                        <div className={'col-span-12'}>
                            <Label isRequired>{t("Доступное время")}</Label>
                            {
                                WEEK_DAYS.map((item, index) => <div
                                    className="flex justify-between mb-1.5 items-center">
                                    <span className={'w-36 text-sm font-bold'}>{get(item, 'label')}</span>
                                    <TimePicker className={' rounded-xl border-none'} format={"HH:mm"}
                                                onChange={(val) => setAvailableTimes(prev => ({
                                                    ...prev,
                                                    [index + 1]: {...prev[index + 1], start_time: val}
                                                }))} value={availableTimes[index + 1]?.start_time}/>
                                    <span className={' text-sm font-bold'}>{t("до")}</span>
                                    <TimePicker className={' rounded-xl border-none'} format={"HH:mm"}
                                                onChange={(val) => setAvailableTimes(prev => ({
                                                    ...prev,
                                                    [index + 1]: {...prev[index + 1], end_time: val}
                                                }))} value={availableTimes[index + 1]?.end_time}/>
                                </div>)
                            }
                        </div>
                        <Field
                            label={t('Start date')}
                            classNames={'col-span-12'}
                            type={FIELDS.datepicker}
                            params={{required: true}}
                            name={`period_start`}/>
                        <Field
                            defaultValue={null}
                            label={t('End date')}
                            classNames={'col-span-12'}
                            type={FIELDS.datepicker}
                            name={`period_end`}/>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default PractitionerContainer;
